import React, {useState, useEffect} from 'react';
import { Box, Typography, Link, IconButton, Select, MenuItem } from '@mui/material';
import { Facebook, Twitter, Instagram } from '@mui/icons-material';
import logo from '../assets/white_logo.png'; // Update with the correct relative path to your uploaded image
import taxi from '../assets/taxi.png';
function Footer() {
    const [language, setLanguage] = useState('en');
    const [rightMargin, setRightMargein] = useState(10)
    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };
    let intervalID;
    useEffect(()=>{

         intervalID = setInterval(() => {
            setRightMargein(prevMargin=> prevMargin<window.innerWidth ?  prevMargin+2 : -10);

        }, 100);

        return () => clearInterval(intervalID);
    },[])

    return (
        <Box>

<Box sx={{display:'flex', justifyContent:'flex-end' }}>
                <Typography
                        component="img"
                        src={taxi}
                        alt="Company Logo"
                        sx={{
                            // border:'1px solid #ccc',
                            marginRight:`${rightMargin}px`,
                            height: 80,         // Set a fixed height for the logo
                            width: '30',
                            mb:-3,      // Allow width to adjust based on aspect ratio
                            objectFit: 'contain', // Maintain aspect ratio within the set height
                            maxWidth: '150px',  // Optional: Set a max width to avoid oversizing
                            // ml:50
                        }}
                    />
                </Box>

                <Box
            component="footer"
            sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                padding: 3,
                background: 'linear-gradient(135deg, rgba(0, 0, 30, 0.9), rgba(20, 20, 60, 0.8), rgba(50, 50, 90, 0.7))', // Enhanced gradient
                color: '#ffffff', // White text for readability
                borderTop: '1px solid rgba(255, 255, 255, 0.2)', // Subtle border at the top
                fontSize: '14px',
                mt: 'auto', // Ensures footer sticks to the bottom
                fontFamily: '"Open Sans", sans-serif' // Apply Open Sans font
            }}
        >
            {/* Business Information */}
            <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        <Typography
                            component="img"
                            src={logo}
                            alt="Company Logo"
                            sx={{
                                height: 30,
                                mb:2,         // Set a fixed height for the logo
                                width: 'auto',      // Allow width to adjust based on aspect ratio
                                objectFit: 'contain', // Maintain aspect ratio within the set height
                                maxWidth: '150px',  // Optional: Set a max width to avoid oversizing
                                fontFamily: '"Open Sans", sans-serif' // Apply Open Sans font
                                // ml:50
                            }}
                        />
                    </Box>
                <Typography sx={{ color:"#ddd"}}>Business permit: 10166361/0</Typography>
                <Typography sx={{ color:"#ddd"}}>TVA: LU36009186</Typography>
                <Typography sx={{ color:"#ddd",mt:2}}>tripgo.luxembourg@gmail.com</Typography>

                <Typography sx={{ color:"#ddd",mt:1, color:'#999'}}>TripGo @ 2024</Typography>
            </Box>

            {/* Social Media Icons */}
            <Box sx={{ display: 'flex', gap: 1, flexDirection:'column' }}>
            {/* <Select
                        value={language}
                        onChange={handleLanguageChange}
                        sx={{ minWidth: 120 }}
                    >
                        <MenuItem value="en">🇺🇸 English</MenuItem>
                        <MenuItem value="es">🇪🇸 Spanish</MenuItem>
                        <MenuItem value="fr">🇫🇷 French</MenuItem>
                        <MenuItem value="de">🇩🇪 German</MenuItem>
                    </Select> */}
                    <Box>
                <Link href="https://www.facebook.com/profile.php?id=61567890161671" target="_blank" underline="none">
                    <IconButton sx={{ color: '#ffffff' }}>
                        <Facebook />
                    </IconButton>
                </Link>
                <Link href="https://www.instagram.com/tripgo_lux/" target="_blank" underline="none">
                    <IconButton sx={{ color: '#ffffff' }}>
                        <Instagram />
                    </IconButton>
                </Link>
                </Box>
               
            </Box>
        </Box>


        </Box>
        
      
    );
}

export default Footer;
