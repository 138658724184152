import { createTheme, ThemeProvider } from '@mui/material/styles';
// import '@fontsource/open-sans';

const theme = createTheme({
    typography: {
        fontFamily: '"Open Sans", sans-serif',
    },
});

export default theme;
