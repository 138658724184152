import React, { useEffect } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { Typewriter } from 'react-simple-typewriter';
import Footer from './components/Footer';
import backgroundImage from './assets/b1.jpg'; // Update with the correct relative path to your uploaded image
import logo from './assets/logo.png'; // Update with the correct relative path to your uploaded image
import { WhatsApp } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // Adjust the path to your theme file

function App() {
  const handleIframeLoad = () => {
    if (window.iFrameResize) {
      window.iFrameResize({
        log: true, // Debugging enabled to trace issues
        autoResize: true, // Enable dynamic resizing
        checkOrigin: false, // Allow cross-origin resizing
        heightCalculationMethod: 'max', // Ensures the iframe takes the max height of the content
        targetOrigin: '*',
      }, '#eto-iframe-booking');
    }
  };

  useEffect(() => {
    // Dynamically load the iframeResizer script only once
    const script = document.createElement('script');
    script.src = 'https://tripgo.trial.easytaxioffice.com/assets/plugins/iframe-resizer/iframeResizer.min.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: '#FFFFFF' // Adjust text color for visibility
        }}
      >
        {/* Header */}
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-around',
          p: 1,
          m: 1,
        }}>
          <Typography
            component="img"
            src={logo}
            alt="Company Logo"
            sx={{
              height: 60,         // Set a fixed height for the logo
              width: 'auto',      // Allow width to adjust based on aspect ratio
              objectFit: 'contain', // Maintain aspect ratio within the set height
              maxWidth: '150px',  // Optional: Set a max width to avoid oversizing
            }}
          />
          <Button
            href="https://wa.me/352661392929"
            target="_blank"
            variant="contained"
            startIcon={<WhatsApp />}
            sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', color: 'green', borderRadius: 5 }}
          >
            +352 661 39 29 29
          </Button>
        </Box>

        {/* Main Content */}
        <Container component="main" sx={{ flexGrow: 1, my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {/* Typewriter Effect Text */}
          <Typography variant="h4" sx={{ mb: 2, textAlign: 'center', padding: '10px', borderRadius: '8px' }}>
           TripGo  {' '}
            <span style={{ color: '#fff', fontWeight: 'bold' }}>
              <Typewriter
                words={['Fast', 'Reliable', 'Affordable']}
                loop={false}
                cursor
                cursorStyle="|"
                typeSpeed={100}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
          </Typography>



          {/* Dynamic iFrame for Booking */}
          <Box sx={{ width: '100%', maxWidth: 800, backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: 3, borderRadius: '12px', boxShadow: '0 8px 12px rgba(0, 0, 0, 0.3)' }}>
            <iframe
              src="https://tripgo.trial.easytaxioffice.com/booking?site_key=7e3f3d3085b900d598bc40543d611575"
              id="eto-iframe-booking"
              allow="geolocation"
              width="100%"
              scrolling="no"
              frameBorder="0"
              style={{ width: '1px', minWidth: '100%', minHeight: '250px', border: '0' }}  // Set a minimum height but allow dynamic resizing
              title="Booking Widget"
              onLoad={handleIframeLoad}
            ></iframe>
          </Box>
        </Container>

        {/* Footer */}
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
